import React, { Component, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import * as L from "leaflet";
import MapHelper from "./../../../helpers/MapHelper";
import ZoomControl from "./ui/ZoomControl";
import MapTypeControl from "./ui/MapTypeControl";
import ShapesControl from "./ui/ShapesControl";
import MapSearchControl from "./ui/MapSearchControl";
import OnMapInfoPanel from "./ui/OnMapInfoPanel";
import MapWithMeasurement from './ui/MapWithMeasurement';


import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useMap } from "../../../providers/MapProvider";
import ShapeStyling from "../shapes/ShapeStyling";
import {useApi} from "../../../providers/ApiProvider";
import UsersModel from "../../../models/UsersModel";
import CoordinatesModel from "../../../models/CoordinatesModel";
import MapSearchControlGetAddress from "./ui/MapSearchControlGetAddress";
import { useAuth } from "../../../providers/AuthProvider";
import UnionShape from "./UnionShape";
import ProjectsModel from "../../../models/ProjectsModel";
import Loading from "../../ui/Loading";
import ImportExportControl from "./ui/ImportExportControl";
import MapInfoPanel from "./ui/MapInfoPanel";
import ShapePanelControl from "../shapes/ShapePanelControl";
import {useParams} from "react-router-dom";
import ShapesListPanel from "../shapes/ShapesListPanel";
import ShapesModel from "../../../models/ShapesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import UnitsToggle from "./ui/UnitsToggle";
import ImportFromFilesModal from "./ui/ImportFromFilesModal";
import EmailReportModal from "./ui/EmailReportModal";


export default function Map({ center, mapType, project, hideToolbar = false }) {
    const mapContext = useMap();
    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);
    const {user} = useAuth();
    const notifications = useNotifications();
    const {projectId} = useParams();

    const [projectInfo, setProjectInfo] = React.useState({ title: '', description: '' });
    const [curCenter, setCurCenter] = React.useState(center ? center : MapHelper.defaultCenter);
    const [selectedMapType, setSelectedMapType] = React.useState(mapType ? mapType : MapHelper.defaultMapType);
    const [map, setMap] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState({});
    const [shapes, setShapes] = React.useState({});
    const [allShapesShown, setAllShapesShown] = React.useState(false);
    const [infoPanelsMinified, setInfoPanelsMinified] = React.useState(false);
    let unionShape = useRef();
    const [locations, setLocations] = React.useState({ positive: [], negative: [], stats: {total: 0, positive: 0, negative: 0} });
    const [locationsLoading, setLocationsLoading] = React.useState(false);
    const [cursorPosition, setCursorPosition] = React.useState({ lat: 0, lng: 0 });
    const [rulerActive, setRulerActive] = React.useState(0);
    const [emailReportModalShown, setEmailReportModalShown] = React.useState(false);

    const mapRef = React.useRef(null);
    const mapSearchedLocationRef = React.useRef(null);

    const coordinatesModel = new CoordinatesModel(apiContext.api);
    const projectsModel = new ProjectsModel(apiContext.api);


    useEffect(() => {
        if (!mapContext.isInitialized) {
            const mapElements = MapHelper.initMap(mapRef.current, curCenter);

            mapContext.init(mapElements.map);
            setMapLayers(mapElements.layers);

            if (project && project.data && project.data.shapes) {
                setShapes(project.data.shapes);

                unionShape.current = new UnionShape(project.data.shapes);

                onShapesChanged(project.data.shapes, true, false);
            }

            setProjectInfo({
                title: project.title,
                description: project.description
            });
        }
    }, [project]);


    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);
            if (unionShape.current && unionShape.current.bounds && unionShape.current.bounds.isValid()) {
                mapContext.map.fitBounds(unionShape.current.bounds, {paddingTopLeft: [64, 160], paddingBottomRight: [64, 64]});
            }
        }
    }, [mapContext.isInitialized]);


    useEffect(() => {
        if (map && mapLayers[selectedMapType]) {
            mapLayers[selectedMapType].addTo(map);

            Object.keys(mapLayers).forEach((key) => {
                if (key !== selectedMapType) {
                    map.removeLayer(mapLayers[key]);
                }
            });

            map.on('mousemove', onMouseMove);
        }
    }, [map, mapLayers, selectedMapType]);


    useEffect(() => {
        if (mapContext.isInitialized) {
        }
    }, [shapes]);


    const checkAccess = (e) => {
        if (!user || !user.id) {
            e.preventDefault();
            e.stopPropagation();

            window.location.href = '/auth/login';
        }
    }


    const onMapSearchControlChange = (lat, lng, additional) => {
        map.setView([lat, lng], MapHelper.defaultZoomLevelDetailed);

        if (!mapSearchedLocationRef.current) {
            mapSearchedLocationRef.current = L.marker([lat, lng]).addTo(map);
        } else {
            mapSearchedLocationRef.current.setLatLng([lat, lng]);
        }

        if (additional && additional.formatted_address) {
            let popupContent = '<div class="p-2">' + additional.formatted_address.filter((item) => item.length > 0).join('<br>') + '</div>';
            mapSearchedLocationRef.current.bindPopup(popupContent).openPopup();
        }
        /*if (locationData.bounds) {
            map.fitBounds(locationData.bounds);
        }*/
    }


    const onMapTypeControlChange = (layerName) => {
        setSelectedMapType(layerName);
    }


    const refreshLocations = (actualShapes) => {
        if (unionShape.current.bounds === {} || !unionShape.current.bounds.isValid()) {
            return;
        }
        if (locationsLoading) {return;}
        setLocationsLoading(true);

        const data = {
            s: unionShape.current.bounds.getSouth(),
            w: unionShape.current.bounds.getWest(),
            n: unionShape.current.bounds.getNorth(),
            e: unionShape.current.bounds.getEast(),
        }

        let dataPoly = [];
        if (unionShape.current.shapes) {
            Object.values(unionShape.current.shapes).forEach((shape) => {
                if (!(shape.geoJSON && shape.geoJSON.geometry && shape.geoJSON.geometry.coordinates)) {return;}

                let shapeDryBounds = L.geoJSON(shape.geoJSON).getBounds();
                let shapeDryData = {
                    id: shape.id,
                    coordinates: shape.geoJSON.geometry.coordinates,
                    bounds: {
                        s: shapeDryBounds.getSouth(),
                        w: shapeDryBounds.getWest(),
                        n: shapeDryBounds.getNorth(),
                        e: shapeDryBounds.getEast(),
                    },
                    diameter: MapHelper.calculateBoundsDiameter(shapeDryBounds),
                };
                dataPoly.push(shapeDryData);
            });
        }

        let oversizedShapes = [];
        let coordsFetched = dataPoly.map((dataPolyItem) => {
            if (dataPolyItem.diameter > 50000) {
                notifications.notify('Shape "'+dataPolyItem.id+'" is too large to fetch coordinates.', 'warning');
                oversizedShapes.push(dataPolyItem.id);
                return;
            }

            return coordinatesModel.listPolygon({data: [dataPolyItem]});
        });
        if (oversizedShapes.length) {
            let newShapes = {...actualShapes};
            oversizedShapes.forEach((shapeId) => {
                if (newShapes[shapeId]) {
                    delete newShapes[shapeId];
                }
            });
            setShapes(newShapes);
            actualShapes = newShapes;
        }
        Promise.all(coordsFetched).then((responses) => {
            // merge all responses in a single array
            let freshLocations = [];
            responses.forEach((response) => {
                if (response) {
                    freshLocations = freshLocations.concat(response);
                }
            });

            if (!freshLocations.length) {
                console.log('Error fetching coordinates.');
            } else {
                processFreshLocations(actualShapes, freshLocations);
            }
        }).finally(() => {
            setLocationsLoading(false);
        });
    }


    const processFreshLocations = (actualShapes, freshLocations) => {
        let fullLocationsPerShape = {},
            positiveLocations = [],
            positiveLocationsCount = 0,
            negativeLocations = [],
            negativeLocationsCount = 0,
            positiveLocationsPerShape = {},
            negativeLocationsPerShape = {};

        Object.keys(actualShapes).forEach((key) => {
            fullLocationsPerShape[key] = {
                positive: [],
                negative: [],
                counts: {
                    total: 0,
                    positive: 0,
                    negative: 0
                }
            };
            positiveLocationsPerShape[key] = 0;
            negativeLocationsPerShape[key] = 0;
        });


        freshLocations.forEach((location) => {
            let shapesWithLocation = [], locationUnionType = true;

            Object.keys(actualShapes).forEach((key) => {
                // check if location is in this shape
                if (actualShapes[key].geoJSON && MapHelper.coordsInShape(L.latLng(location.lat, location.lng), actualShapes[key].geoJSON)) {
                    shapesWithLocation.push(key);

                    // check if this shape excludes this location
                    if (actualShapes[key].geoJSON.properties && (actualShapes[key].geoJSON.properties.union === false)) {
                        locationUnionType = false;
                    }
                }
            });


            Object.keys(actualShapes).forEach((key) => {
                if (shapesWithLocation.includes(key)) {
                    if (locationUnionType) {
                        positiveLocationsPerShape[key] += location.plot_count;
                        positiveLocationsCount += location.plot_count;
                        positiveLocations.push(location);
                        fullLocationsPerShape[key].positive.push(location);
                        fullLocationsPerShape[key].counts.positive += location.plot_count;
                    } else {
                        negativeLocationsPerShape[key] += location.plot_count;
                        negativeLocationsCount += location.plot_count;
                        negativeLocations.push(location);
                        fullLocationsPerShape[key].negative.push(location);
                        fullLocationsPerShape[key].counts.negative += location.plot_count;
                    }

                    fullLocationsPerShape[key].counts.total += location.plot_count;
                }
            });
        });


        setLocations({
            byShape: fullLocationsPerShape,
            positive: positiveLocations,
            negative: negativeLocations,
            stats: {
                total: positiveLocationsCount + negativeLocationsCount,
                positive: positiveLocationsCount,
                negative: negativeLocationsCount,
                positiveLocationsPerShape: positiveLocationsPerShape,
                negativeLocationsPerShape: negativeLocationsPerShape ? negativeLocationsPerShape : 0
            }
        });
    }


    const onShapesChanged = (newShapes = null, shouldRefreshLocations = true, shouldUpdateProject = true) => {
        setLocationsLoading(true);
        unionShape.current.refresh(Object.values(newShapes ? newShapes : shapes));

        const data = {
            shapes: unionShape.current.shapes,
            union: unionShape.current.union,
            bounds: unionShape.current.bounds,
            locations: {
                total: locations.stats.total ? locations.stats.total : 0,
                positive: locations.stats.positive ? locations.stats.positive : 0,
                negative: locations.stats.negative ? locations.stats.negative : 0
            }
        };

        let projectUpdated = new Promise((resolve, reject) => {resolve(true);});
        if (shouldUpdateProject) {
            projectUpdated = projectsModel.update(project.id, { shapes: data });
        }
        projectUpdated.then((response) => {
            if (shouldRefreshLocations) {
                refreshLocations(data.shapes);
            }
        }).finally(() => {
            if (!shouldRefreshLocations) {
                setLocationsLoading(false);
            }
        });
    }


    const onAddShapeClick = (type) => {
        let newShapes = {...shapes};
        Object.keys(newShapes).forEach((key) => {
            newShapes[key].active = false;
            if (!newShapes[key].geoJSON) {
                delete newShapes[key];
            }
        });

        if (type) {
            let shape = {
                id: ShapeStyling.generateRandomId(),
                type: type,
                active: true,
            };
            newShapes[shape.id] = shape;
        }

        setShapes(newShapes);
    }


    const onShapeActiveChange = (id, active, geoJSON, layerData) => {
        if (active) {
            let newShapes = {...shapes};

            Object.keys(newShapes).forEach((key) => {
                if (newShapes[key].active === true) {
                    newShapes[key].active = false;
                }
            });

            newShapes[id].active = true;

            setShapes(newShapes);
        } else {
            let shapesEdited = false, coordinatesChanged = false;
            if (geoJSON && !_.isEqual(shapes[id].geoJSON, geoJSON)) {
                shapesEdited = true;

                if (shapes[id].geoJSON && !_.isEqual(shapes[id].geoJSON.geometry, geoJSON.geometry) || !shapes[id].geoJSON) {
                    coordinatesChanged = true;
                }
            }

            let newShapes = {...shapes};
            newShapes[id].active = false;
            if (geoJSON) {
                newShapes[id].geoJSON = geoJSON;
                newShapes[id].bounds = L.geoJSON(geoJSON).getBounds();
            }
            if (layerData) {newShapes[id].layerData = layerData;}

            setShapes(newShapes);

            if (shapesEdited) {
                onShapesChanged(newShapes, coordinatesChanged);
            }
        }
    }


    const onShapeDelete = (id) => {
        let newShapes = {...shapes};

        delete newShapes[id];

        setShapes(newShapes);
        onShapesChanged(newShapes);
    }


    const onShapeToggle = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.union === false) {
                newShapes[id].geoJSON.properties.union = true;
            } else {
                newShapes[id].geoJSON.properties.union = false;
            }
        }

        setShapes(newShapes);
        onShapesChanged(newShapes);
    }


    const onShapeToggleVisible = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.visible === false) {
                newShapes[id].geoJSON.properties.visible = true;
            } else {
                newShapes[id].geoJSON.properties.visible = false;
            }
        } else {
            newShapes[id].geoJSON.properties = { visible: false };
        }

        setShapes(newShapes);
    }


    const onShapeActivate = (id) => {
        onShapeActiveChange(id, true);

        if (shapes[id].bounds) {
            let newBounds = L.latLngBounds(shapes[id].bounds._southWest, shapes[id].bounds._northEast);
            if (newBounds.isValid()) {
                mapContext.map.fitBounds(newBounds);
            }
        }
    }


    const onMouseMove = (e) => {
        setCursorPosition(e.latlng);
    };


    const onShapesImported = (importedShapes, bounds) => {
        setShapes(importedShapes);

        if (bounds) {
            map.fitBounds(bounds);
        }
    }


    const onImportFromFiles = (fileId) => {
        shapesModel.view(fileId).then((response) => {
            if (response !== false && response.data) {
                let newShapeDraft = {
                    id: ShapeStyling.generateRandomId(),
                    type: MapHelper.detectShapeType(response.data),
                    geoJSON: response.data,
                    bounds: L.geoJSON(response.data).getBounds(),
                    layerData: {
                        imported: true
                    },
                    active: false,
                };

                let newShapes = {...shapes};
                newShapes[newShapeDraft.id] = newShapeDraft;
                onShapesChanged(newShapes, true);
                setShapes(newShapes);
                notifications.notify('File imported successfully', 'success');

                map.fitBounds(newShapeDraft.bounds);
            }
        });
    }


    const onRulerActive = (rulerAct) => {
        setRulerActive(rulerAct);
    };


    const showEmailReportModal = () => {
        setEmailReportModalShown(true);
    }


    return (
        <div className="map-panel">
            <div id="map-container" ref={mapRef}></div>

            <div className={'map-area map-top'}>
                <MapSearchControlGetAddress address={''} onChange={onMapSearchControlChange} />
            </div>

            {project && project.data && (
            <div className={'map-area project-info'}>
                <OnMapInfoPanel className={'style-warning'}>
                    <MapInfoPanel
                        project={projectInfo}
                        type={'title'}
                        onValueChange={(value) => {
                            let newProjectInfo = {...projectInfo};
                            newProjectInfo.title = value;
                            setProjectInfo(newProjectInfo);
                        }}
                    >
                        <h1 className="h5 m-0">
                            {projectInfo.title}
                            <div className="fs-1 mt-1 text-muted">ID: {project.id}</div>
                            <div className="fs-1 mt-1 text-muted">Shapes Version: {project.shapes_version}</div>

                            <button onClick={() => {
                                showEmailReportModal();
                            }} className="align-items-center border-0 btn d-flex float-start mt-2 p-0">
                                <i className={'ti fs-4 ti-mail'}></i>
                                <span className={'fs-2 ms-2'}>Email Report</span>
                            </button>

                            <button onClick={() => {
                                setInfoPanelsMinified(!infoPanelsMinified)
                            }} className="align-items-center border-0 btn d-flex float-end mt-2 p-0">
                                <i className={'ti fs-4 ' + (infoPanelsMinified ? 'ti-arrows-diagonal' : 'ti-arrows-diagonal-minimize-2')}></i>
                                <span className={'fs-2 ms-2'}>{infoPanelsMinified ? 'Maximize' : 'Minimize'} Panel</span>
                            </button>

                            <button onClick={() => {
                                onShapesChanged();
                                notifications.notify('Updating project information: counts may be changed!', 'warning');
                            }} className="align-items-center border-0 btn d-flex float-end mt-2 me-3 p-0">
                                <i className={'ti fs-4 ti-refresh'}></i>
                                <span className={'fs-2 ms-2'}>Refresh Info</span>
                            </button>
                        </h1>
                    </MapInfoPanel>
                </OnMapInfoPanel>
                {infoPanelsMinified === false && (<>
                    {projectInfo.description && (
                        <OnMapInfoPanel className={'style-warning'}>
                            <MapInfoPanel
                                project={projectInfo}
                                type={'description'}
                                onValueChange={(value) => {
                                    let newProjectInfo = {...projectInfo};
                                    newProjectInfo.description = value;
                                setProjectInfo(newProjectInfo);
                        }}
                    >
                        <div>{projectInfo.description.split('\n').map((str, index, array) =>
                            ((index === array.length - 1) || (!str.length)) ? str :
                                <p key={index} className={index < (array.length - 1) ? 'mb-1' : ''}>{str}</p>
                        )}</div>
                    </MapInfoPanel>
                </OnMapInfoPanel>
                )}
                <OnMapInfoPanel className={'style-warning'}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                            Total mailing count: {locations.stats.positive} / {locations.stats.total} <Loading show={locationsLoading}/>
                        </div>
                        <div className="">
                            {Object.keys(shapes).length} Shape(s)
                            <a href="#" className="ms-3" onClick={(e) => {
                                e.preventDefault();
                                setAllShapesShown(!allShapesShown);
                            }}>{allShapesShown ? 'Hide' : 'Show'}</a>
                        </div>
                    </div>
                </OnMapInfoPanel>
                </>)}
                <ShapesListPanel
                    visible={allShapesShown}
                    onShapeDelete={onShapeDelete}
                    onShapeActivate={onShapeActivate}
                    onShapeToggle={onShapeToggle}
                    onShapeToggleVisible={onShapeToggleVisible}
                    shapes={shapes}
                    locations={locations}
                />


                {rulerActive && (
                    <OnMapInfoPanel className={'style-warning'}>
                        <MapWithMeasurement />
                    </OnMapInfoPanel>
                )}
            </div>
            )}
            <div className={'map-area map-corner-top-left'+(hideToolbar === true ? ' d-none' : '')} onClick={checkAccess}>
                <ImportExportControl
                    projectId={project.id}
                    onShapesImported={onShapesImported}
                    shapes={shapes}
                    onImportFromFiles={onImportFromFiles}
                />


                <ShapesControl
                    locations={locations}
                    shapes={shapes}
                    onShapesChanged={() => {onShapesChanged();}}
                    onAddShapeClick={onAddShapeClick}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    checkAccess={checkAccess}
                    onRulerActive={onRulerActive}
                />
            </div>
            <div className={'map-area map-corner-top-right'}>
                <MapTypeControl onChange={onMapTypeControlChange} />

                <ShapePanelControl
                    locations={locations}
                    shapes={shapes}
                    onShapesChanged={() => {onShapesChanged();}}
                    onAddShapeClick={onAddShapeClick}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    checkAccess={checkAccess}
                />
            </div>
            <div className={'map-area map-corner-bottom-left'}>
                <OnMapInfoPanel data={`lat: ${cursorPosition.lat.toFixed(4)} lng: ${cursorPosition.lng.toFixed(4)}`} />
            </div>
            <div className={'map-area map-corner-bottom-right flex-row align-items-end'}>
                <UnitsToggle />

                <ZoomControl onZoomIn={() => {
                    map.setZoom(map.getZoom() + 1)
                }} onZoomOut={() => {
                    map.setZoom(map.getZoom() - 1)
                }} />
            </div>


            <EmailReportModal
                locations={locations}
                visible={emailReportModalShown}
                onCancel={() => {console.log(123); setEmailReportModalShown(false);}}
            />
        </div>
    );
}