import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import {useApi} from "../../providers/ApiProvider";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";
import imgLogo from "../../assets/img/home.jpg";

export default function Home(props) {
    const {language} = useMyTranslation();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();


    useEffect(() => {
        breadcrumbs.update({
            title: null
        });

        document.title = 'Welcome | Geolist';
    }, [language]);


    return (
        <div className="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center w-100">
                <div className="row justify-content-center w-100">
                    <div className="col-lg-4">
                        <div className="text-center">
                            <img src={imgLogo} alt="" className="img-fluid" />
                            <h1 className="fw-semibold mb-7 fs-9">{'Welcome!'}</h1>
                            <h4 className="fw-semibold mb-7">{'This is a template page, please navigate to your project.'}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}