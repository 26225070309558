import React, {useCallback, useEffect, useState} from 'react';

import Shape from "../../shapes/Shape";
import ShapeStyling from "../../shapes/ShapeStyling";

import Locations from "../../locations/Locations";
import {useParams} from "react-router-dom";
import {Tooltip} from "react-tooltip";


export default function ShapesControl({ locations, shapes, onShapesChanged, onAddShapeClick, checkAccess, onRulerActive, }) {
    const {projectId} = useParams();

    const [rulerActive, setRulerActive] = useState(false);
    const [btnActive, setBtnActive] = useState('');


    useEffect(() => {
        let shapesActive = true;
        if (shapes && Object.keys(shapes).length) {
            Object.values(shapes).forEach((shape) => {
                if (!shape.active) {
                    shapesActive = false;
                }
            });
        }

        if (!shapesActive && btnActive) {
            setBtnActive('');
        }
    }, [shapes]);


    const onRefreshClick = (e) => {
        e.preventDefault();
        checkAccess();

        onShapesChanged();
    }


    const onRulerClick = (e) => {
        e.preventDefault();
        checkAccess();

        setRulerActive(!rulerActive);

        onRulerActive(rulerActive);
    }


    const onAddClick = (e, type) => {
        e.preventDefault();
        checkAccess();
        if (btnActive === 'new-shape-' + type) {
            setBtnActive('');
            onAddShapeClick(null);
        } else {
            setBtnActive('new-shape-' + type);
            onAddShapeClick(type);
        }
    }


    const onDownloadCatids = (e) => {
        e.preventDefault();
        checkAccess();

        let catids = [];
        Object.keys(locations.byShape).forEach((key) => {
            locations.byShape[key].positive.forEach((location) => {
                if (!catids.length) {
                    let headers = ['ProjectId'].concat(Object.keys(location));
                    catids.push(headers);
                }

                let row = [projectId].concat(Object.values(location));
                catids.push(row);
            });
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += catids.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "catids-"+projectId+".csv");
        document.body.appendChild(link); // Required for FF

        link.click();

        document.body.removeChild(link);
    }




    return (
        <>
            <div className="btn-group-vertical">
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POLYGON)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POLYGON ? ' active' : '')}

                    data-tooltip-content="Draw a polygon Shape"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-polygon fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_RECTANGLE)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_RECTANGLE ? ' active' : '')}

                    data-tooltip-content="Draw a Rectangle"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-rectangle fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_CIRCLE)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_CIRCLE ? ' active' : '')}

                    data-tooltip-content="Draw a radius"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-circle-dot fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POINT)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POINT ? ' active' : '')}

                    data-tooltip-content="Draw a Point"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-map-pin fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POLYLINE)}
                    className={'btn d-none btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POLYLINE ? ' active' : '')}

                    data-tooltip-content="Buffer tool"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-box-padding fs-5'}></i>
                </button>
            </div>

            <div className="btn-group-vertical">
                <button
                    onClick={onRulerClick}
                    className={'btn btn-onmap'}

                    data-tooltip-content="Measure distance"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-ruler fs-5'}></i>
                </button>
            </div>

            {shapes && Object.keys(shapes).length ? (
                <div className="btn-group-vertical">
                    <button
                        onClick={onRefreshClick}
                        className={'btn btn-onmap'}

                        data-tooltip-content="Generate Counts"
                        data-tooltip-place="right"
                        data-tooltip-id="shapes-control-tooltip"
                    >
                        <i className={'ti ti-refresh-dot fs-5'}></i>
                    </button>
                </div>
            ) : ''}

            <div className="btn-group-vertical">
                <button
                    onClick={onDownloadCatids}
                    className={'btn btn-onmap'}

                    data-tooltip-content="Export Data"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-table-down fs-5'}></i>
                </button>
            </div>

            <Tooltip id="shapes-control-tooltip" />
        </>
    );
}