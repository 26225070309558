import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsModel from "../../models/ProjectsModel";
import Map from "../map/general/Map";

export default function ProjectView(props) {
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();

    const [data, setData] = React.useState({});


    useEffect(() => {

        projectsModel.view(params.projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                if (!response.data || !response.data.union) {
                    let responseJSON = '{"union": {"type": "Feature", "geometry": {"type": "Polygon", "coordinates": []}, "properties": []}, "bounds": [], "shapes": []}';
                    response.data = JSON.parse(responseJSON);
                }
                setData(response);
                document.title = response.title+' | GeoList';
            }
        });
    }, []);


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    return data.id ? (
        <Map project={data} />
    ) : '';
}
