import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import FilesForm from "./FilesForm";
import ShapesModel from "../../models/ShapesModel";

export default function FileCreate(props) {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const notifications = useNotifications();
    const shapesModel = new ShapesModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [redirect, setRedirect] = React.useState('');


    useEffect(() => {
        /*breadcrumbs.update({
            title: 'Додати новий бренд',
            breadcrumbs: [
                {label: 'Бренди', url: '/brands'},
                {label: 'Додати новий'}
            ]
        });*/

        document.title = 'Add a new file | Geolist';

        setLoading(false);
    }, []);


    const onSubmit = (data) => {
        setLoading(true);

        data.data = JSON.parse(data.data);

        shapesModel.create(data, onError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notifications.notify('File saved successfully', 'success');

                setRedirect('/files/'+response.id);
            }
        }).catch((response) => {
            onError(response);
        });
    }


    const onError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notifications.notify('Please fix errors', 'error');
    }


    return (<>
        {redirect && <Navigate to={redirect}/>}
        <div className="d-flex m-block-gap">
            <Link to={'/files'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                Back to Files list
            </Link>
        </div>
        <div className={'row'}>
            <div className={'col-md-8'}>
                <SimpleCardContainer title={'Add a new file'} loading={loading}>
                    <FilesForm
                        formData={formData}
                        className={'py-2'}
                        errors={errors}
                        isNewRecord={true}
                        onSubmit={onSubmit}
                    />
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
