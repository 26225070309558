import React, {useEffect} from 'react';

import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import FormTextField from "../layout/ui/FormTextField";
import FormTextArea from "../layout/ui/FormTextArea";
import FormCheckbox from "../layout/ui/FormCheckbox";
import FormSingleImage from "../layout/ui/FormSingleImage";

export default function FilesForm({formData, onSubmit, errors, loading, isNewRecord, className = ''}) {
    const [data, setData] = React.useState({});


    useEffect(() => {
        setData(formData);
    }, [formData]);


    const updateField = (field, value) => {
        let newData = {...data};
        newData[field] = value;

        setData(newData);
    }


    const onFileChange = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        //get file contents
        let reader = new FileReader();
        reader.onload = function(e) {
            updateField('data', e.target.result);
        };
        reader.readAsText(e.target.files[0]);
    }


    const triggerFileChange = (e) => {
        e.preventDefault();

        document.querySelector('#change-file').click();
    }


    const onSubmitClick = (e) => {
        e.preventDefault();

        onSubmit(data);
    }


    return (
        <form className={'py-2'}>
            <div className={'row'}>
                <div className={'col-md-8'}>
                    <FormTextField
                        label={'Name'}
                        name={'name'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('name', value)
                        }}
                        value={data.name}
                        required={true}
                    />

                    <div className={'row'}>
                        <div className={'col-md-9'}>
                            <FormTextArea
                                label={'File'}
                                name={'data'}
                                errors={errors}
                                onChange={(value) => {
                                    updateField('data', value)
                                }}
                                value={data.data}
                                hint={'Should be JSON formatted.'}
                                className={'form-control'}
                                isCodeMirror={true}
                                groupClassName={'mt-3'}
                            />
                        </div>
                        <div className={'col-md-3'}>
                            <input type="file" className="d-none" id="change-file" onChange={onFileChange}/>

                            <div className="d-flex align-items-center justify-content-center mt-5 gap-3">
                                <button className="btn btn-light-primary text-primary" onClick={triggerFileChange}>{'Upload a data file...'}</button>
                            </div>
                        </div>
                    </div>

                    <div className={'form-group mt-3'}>
                        <button className="btn btn-primary mt-3" onClick={onSubmitClick}>{'Save changes'}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}
