import React, {useEffect} from 'react';
import {useNotifications} from "../../../../providers/NotificationsProvider";
import SimpleModal from "../../../ui/SimpleModal";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import ProjectShareAutocomplete from "../../../projects/ProjectShareAutocomplete";
import {useApi} from "../../../../providers/ApiProvider";
import ShapesModel from "../../../../models/ShapesModel";

export default function ImportFromFilesModal({visible = false, onClick, onCancel}) {
    const notifications = useNotifications();
    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);

    const [modalLoading, setModalLoading] = React.useState(false);
    const [files, setFiles] = React.useState([]);


    useEffect(() => {
        if (!visible) {return;}

        setModalLoading(true);
        shapesModel.list({perPage: 100}).then((response) => {
            if (response !== false && response.data) {
                setFiles(response.data);
            }
        }).finally(() => {
            setModalLoading(false);
        });
    }, [visible]);


    return (
        <SimpleModal visible={visible} onClose={onCancel} loading={modalLoading} headerTitle="Import from My Files">
            {files && files.length ? (
                <div className="mb-4">
                    <h4 className="text-uppercase fs-2 fw-semibold mb-2 pb-1">Select a File</h4>
                    {files.map((file, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <div>{file.name}</div>
                                <div className="text-muted text-capitalize fs-2">{(new Date(file.updated_at)).toLocaleString()}</div>
                            </div>

                            <ButtonWithLoading
                                className="btn-circle btn-light btn-sm justify-content-center"
                                iconClassName="ti ti-plus fs-3"
                                noOriginalIconClassName={true}
                                loading={modalLoading}
                                spinnerClassName="spinner-border spinner-border-sm"
                                noOriginalSpinnerClassName={true}
                                onClick={() => {
                                    onClick(file.id)
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : (<div className="text-muted fs-3">No files available.</div>)}
        </SimpleModal>
    );
}
