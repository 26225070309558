import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";


export default function ShapeNotesDisplay({initialJSON}) {
    const theme = {
    }


    const onError = (error) => {
        console.error(error);
    }


    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
    };
    if (initialJSON) {
        initialConfig.editorState = initialJSON;
    }


    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        ErrorBoundary={LexicalErrorBoundary}
                     placeholder={''}/>
                </div>
            </div>
        </LexicalComposer>
    );
}