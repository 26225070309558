import BaseCrudModel from "./BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ProjectsModel extends BaseCrudModel {
    baseUrl = '/projects';

    constructor(api) {
        super(api);
    }


    async requestReport(projectId, data, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/request-report';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async copy(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/copy';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }
}