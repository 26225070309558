import { createRoot } from 'react-dom/client';
import * as L from "leaflet";
import 'leaflet.markercluster';
import 'leaflet.markercluster.freezable';
import {useMap} from "../../../providers/MapProvider";
import {useEffect, useRef, useState} from "react";

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import MarkerPopup from "./MarkerPopup";
import ShapeStyling from "../shapes/ShapeStyling";
import chroma from "chroma-js";
import {useApi} from "../../../providers/ApiProvider";


class ClusterStyling {
    static getPositiveClusterIcon(cluster, shapeColor) {
        if (!shapeColor) {shapeColor = ShapeStyling.COLOR_POSITIVE;}
        const contrastColor = chroma.contrast(shapeColor, 'white') > 2 ? 'white' : 'black';

        let style = '--cluster-color: '+shapeColor+'; --cluster-text-color: '+contrastColor+';';
        return L.divIcon({ html: '<div class="cluster-styled" style="'+style+'">' + cluster.getChildCount() + '</div>', className: 'cluster-styled-cover'});
    }

    static getNegativeClusterIcon(cluster) {
        let shapeColor = ShapeStyling.COLOR_NEGATIVE;
        const contrastColor = chroma.contrast(shapeColor, 'white') > 2 ? 'white' : 'black';

        let style = 'opacity: 0.5; --cluster-color: '+shapeColor+'; --cluster-text-color: '+contrastColor+';';
        return L.divIcon({ html: '<div class="cluster-styled" style="'+style+'">' + cluster.getChildCount() + '</div>', className: 'cluster-styled-cover'});
    }
}


export default function Locations({locationsByShape, shapeColor = null, clusterified}) {
    const mapContext = useMap();
    const apiContext = useApi();

    const markerClusterPositive = useRef(L.markerClusterGroup({iconCreateFunction: (cluster) => ClusterStyling.getPositiveClusterIcon(cluster, shapeColor)}));
    const markersPositive = useRef([]);
    const markerClusterNegative = useRef(L.markerClusterGroup({iconCreateFunction: (cluster) => ClusterStyling.getNegativeClusterIcon(cluster)}));
    const markersNegative = useRef([]);
    const pin = '<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4_2)"><path style="" fill-rule="evenodd" clip-rule="evenodd" d="M6.03022e-05 256.168C-0.0959397 114.779 114.443 0.0960151 255.832 1.50705e-05C397.261 -0.0479849 511.952 114.571 512 256C512 397.317 397.485 511.904 256.168 512C114.779 512.096 0.0960603 397.557 6.03022e-05 256.168ZM113 256.094C112.946 177.115 176.927 113.054 255.906 113C334.908 112.973 398.973 176.999 399 256C399 334.939 335.033 398.946 256.094 399C177.115 399.054 113.054 335.073 113 256.094Z" /></g><defs><clipPath id="clip0_4_2"><rect width="512" height="512" fill="white"/></clipPath></defs></svg>';


    const renderPopup = (marker) => {
        let popup = marker.getPopup();
        if (!popup) {
            marker.bindPopup('');
            popup = marker.getPopup();
        }

        const container = document.createElement('div');
        const root = createRoot(container);
        root.render(<MarkerPopup marker={marker} apiContext={apiContext} />);

        popup.setContent(container);
        popup.openPopup();
    }
    const renderPopupRef = useRef(renderPopup);
    useEffect(() => {
        renderPopupRef.current = renderPopup;
    }, [renderPopup]);


    useEffect(() => {
        if (clusterified) {
            markerClusterPositive.current.enableClustering();
            markerClusterNegative.current.enableClustering();
        } else {
            markerClusterPositive.current.disableClustering();
            markerClusterNegative.current.disableClustering();
        }
    }, [clusterified]);


    const createMarkerIcon = (union) => {
        let markerColor = shapeColor;
        if (!shapeColor || !union) {markerColor = union ? ShapeStyling.COLOR_POSITIVE : ShapeStyling.COLOR_NEGATIVE;}
        let html = pin.replace('style=""', 'style="--loc-marker-color: '+markerColor+';"');

        return L.divIcon({
            className: 'leaflet-loc-marker',
            html: html,
            iconSize: [16, 16],
            iconAnchor: [8, 16],
            popupAnchor: [0, -24],
        });
    }


    const createMarker = (location, union) => {
        let marker = L.marker([location.lat, location.lng], {
            icon: createMarkerIcon(union),
        });
        marker.on('click', renderPopupRef.current.bind(null, marker));

        L.Util.stamp(marker);

        return marker;
    }


    useEffect(() => {
        if (!mapContext.isInitialized) {return;}
        if (!mapContext.map.hasLayer(markerClusterPositive.current)) {
            mapContext.map.addLayer(markerClusterPositive.current);
        }
        if (!mapContext.map.hasLayer(markerClusterNegative.current)) {
            mapContext.map.addLayer(markerClusterNegative.current);
        }

        markerClusterPositive.current.clearLayers();
        markerClusterNegative.current.clearLayers();

        let joinedLocationsPositive = {}, joinedLocationsNegative = {};
        locationsByShape.positive.forEach((location) => {
            let coords = location.lat+','+location.lng;
            if (!joinedLocationsPositive[coords]) {
                joinedLocationsPositive[coords] = {
                    lat: location.lat,
                    lng: location.lng,
                    locations: []
                };
            }

            joinedLocationsPositive[coords].locations.push(location);
        });
        locationsByShape.negative.forEach((location) => {
            let coords = location.lat+','+location.lng;
            if (!joinedLocationsNegative[coords]) {
                joinedLocationsNegative[coords] = {
                    lat: location.lat,
                    lng: location.lng,
                    locations: []
                };
            }

            joinedLocationsNegative[coords].locations.push(location);
        });

        let markersPositiveNew = [], markersNegativeNew = [];
        Object.values(joinedLocationsPositive).forEach((info) => {
            let marker = createMarker(info, true);
            marker.bindPopup(info.locations.length+' locations');
            markerClusterPositive.current.addLayer(marker);
            markersPositiveNew.push(marker);
        });
        markersPositive.current = markersPositiveNew;
        Object.values(joinedLocationsNegative).forEach((info) => {
            let marker = createMarker(info, false);
            marker.bindPopup(info.locations.length+' locations');
            markerClusterNegative.current.addLayer(marker);
            markersNegativeNew.push(marker);
        });
        markersNegative.current = markersNegativeNew;

        return () => {
            if (mapContext.map.hasLayer(markerClusterPositive.current)) {
                mapContext.map.removeLayer(markerClusterPositive.current);
            }
            if (mapContext.map.hasLayer(markerClusterNegative.current)) {
                mapContext.map.removeLayer(markerClusterNegative.current);
            }
        }
    }, [mapContext.isInitialized, locationsByShape]);


    useEffect(() => {
        if (mapContext.map.hasLayer(markerClusterPositive.current)) {
            markerClusterPositive.current.options.iconCreateFunction = (cluster) => ClusterStyling.getPositiveClusterIcon(cluster, shapeColor);
            markerClusterPositive.current.refreshClusters();
        }
        if (mapContext.map.hasLayer(markerClusterNegative.current)) {
            markerClusterNegative.current.options.iconCreateFunction = (cluster) => ClusterStyling.getNegativeClusterIcon(cluster);
            markerClusterNegative.current.refreshClusters();
        }

        if (markersPositive.current.length) {
            markersPositive.current.forEach((marker) => {
                marker.setIcon(createMarkerIcon(true));
            });
        }
        if (markersNegative.current.length) {
            markersNegative.current.forEach((marker) => {
                marker.setIcon(createMarkerIcon(false));
            });
        }
    }, [shapeColor]);


    return null;
}