import * as L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet.gridlayer.googlemutant";

export default class MapHelper {
    static defaultCenter = L.latLng(51.5062769,-0.1061091);
    static defaultZoomLevel = 15;
    static defaultZoomLevelDetailed = 17;
    static defaultMaxZoom = 24;
    static defaultMapType = 'mapbox';


    static createMapboxTilesLayer() {
        return L.tileLayer('https://api.mapbox.com/styles/v1/' + process.env.REACT_APP_MAPBOX_STYLE + '/tiles/256/{z}/{x}/{y}@2x?access_token=' + process.env.REACT_APP_MAPBOX_TOKEN, {
            attribution: '',
            tileSize: 512,
            detectRetina: true,
            zoomOffset: -1,
            maxZoom: MapHelper.defaultMaxZoom
        });
    }


    static createGoogleTilesLayer() {
        return L.gridLayer.googleMutant({
            type: "hybrid", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            maxZoom: MapHelper.defaultMaxZoom
        });
    }


    static createOSMTilesLayer() {
        return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
            maxZoom: MapHelper.defaultMaxZoom
        });
    }


    static initMap(htmlContainer, position = null, zoom = null) {
        let map = L.map(htmlContainer, {
            fadeAnimation: true,
            zoomAnimation: true,
            zoomControl: false,
            doubleClickZoom: false,
            maxZoom: MapHelper.defaultMaxZoom,
        });

        //map.pm.setGlobalOptions(ShapeStyling.drawingOptions);


        let mapboxLayer = MapHelper.createMapboxTilesLayer();
        let googleLayer = MapHelper.createGoogleTilesLayer();
        let osmLayer = MapHelper.createOSMTilesLayer();


        if (!position) {
            position = MapHelper.defaultCenter;
        }
        if (!zoom) {
            zoom = MapHelper.defaultZoomLevel;
        }
        map.setView(position, zoom);


        return {
            map: map,
            layers: {
                mapbox: mapboxLayer,
                google: googleLayer,
                osm: osmLayer
            }
        };
    }


    static getUnionShape(shapes) {
        let geoJSONs = turf.polygon([]);

        Object.values(shapes).forEach((shape) => {
            if (shape.geoJSON) {
                if (shape.geoJSON.type === 'FeatureCollection') {
                    shape.geoJSON.features.forEach((feature) => {
                        geoJSONs = turf.union(feature.geometry, geoJSONs);
                    });
                } else if (shape.geoJSON.geometry && !['Point', 'LineString'].includes(shape.geoJSON.geometry.type)) {
                    //console.log(shape.geoJSON.geometry.type);
                    geoJSONs = turf.union(shape.geoJSON, geoJSONs);
                }
            }
        });


        return geoJSONs;
    }


    static coordsInShape(latLng, shape) {
        if (shape.geometry.type === 'LineString') {return false;}
        let point = turf.point([latLng.lng, latLng.lat]);
        return turf.booleanPointInPolygon(point, shape);
    }


    static circleToPolygon(circle) {
        const center = circle.getLatLng();
        const radius = circle.getRadius();

        const options = {steps: 64, units: 'meters'}; // Increase steps for a smoother circle
        return turf.circle([center.lng, center.lat], radius, options);
    }


    static detectShapeType(feature) {
        if (feature.geometry.type === 'Polygon') {
            return 'Polygon';
        } else if (feature.geometry.type === 'LineString') {
            return 'polyline';
        } else if (feature.geometry.type === 'Point') {
            return 'marker';
        }
    }


    static getGeoJSONBounds(geoJSON) {
        const shape = L.geoJSON(geoJSON);
        return shape.getBounds();
    }


    static calculateBoundsDiameter(bounds) {
        return bounds.getNorthEast().distanceTo(bounds.getSouthWest());
    }
}