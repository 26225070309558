import L from "leaflet";

export default class ShapeStyling {
    static TYPE_POLYGON = 'Polygon';
    static TYPE_RECTANGLE = 'Rectangle';
    static TYPE_CIRCLE = 'Circle';
    static TYPE_POINT = 'Marker';
    static TYPE_POLYLINE = 'Line';

    static COLOR_POSITIVE = '#388E3C';
    static COLOR_NEGATIVE = '#D32F2F';

    static DRAWING_OPTIONS_POSITIVE = {
        snappable: true,
        snapDistance: 20,
        allowSelfIntersection: false,
        removeVertexOn: 'dblclick',
        tooltips: false,
        pathOptions: {
            showMeasurements: false,
            showOnHover: true,
            measurementOptions: {
                imperial: false,
            },
            color: '#388E3C',
            weight: 2,
            fillColor: '#388E3C',
            fillOpacity: 0.3,
            pane: 'tilePane',
        },
        templineStyle: {
            showMeasurements: false,
            showOnHover: true,
            measurementOptions: {
                imperial: false,
            },
            color: '#388E3C',
            weight: 2,
            fillColor: '#388E3C',
            fillOpacity: 0.3,
        },
        hintlineStyle: {
            showMeasurements: false,
            showOnHover: true,
            measurementOptions: {
                imperial: false,
            },
            color: '#388E3C',
            weight: 2,
            fillColor: '#388E3C',
            fillOpacity: 0.3,
        },
        markerStyle: {
            icon: L.divIcon({
                className: 'leaflet-loc-marker',
                html: '<svg style="--loc-marker-color: #388E3C;" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m32 0a24.0319 24.0319 0 0 0 -24 24c0 17.23 22.36 38.81 23.31 39.72a.99.99 0 0 0 1.38 0c.95-.91 23.31-22.49 23.31-39.72a24.0319 24.0319 0 0 0 -24-24zm0 35a11 11 0 1 1 11-11 11.0066 11.0066 0 0 1 -11 11z"/></g></svg>',
                iconSize: [24, 24],
                iconAnchor: [12, 24],
            })
        }
    }

    static DRAWING_OPTIONS_NEGATIVE = {
        showMeasurements: true,
        showOnHover: true,
        snappable: true,
        snapDistance: 20,
        allowSelfIntersection: false,
        removeVertexOn: 'dblclick',
        tooltips: false,
        pathOptions: {
            color: '#D32F2F',
            weight: 2,
            fillColor: '#D32F2F',
            fillOpacity: 0.3,
            pane: 'tilePane',
        },
        templineStyle: {
            color: '#D32F2F',
            weight: 2,
            fillColor: '#D32F2F',
            fillOpacity: 0.3,
        },
        hintlineStyle: {
            color: '#D32F2F',
            weight: 2,
            fillColor: '#D32F2F',
            fillOpacity: 0.3,
        },
        markerStyle: {
            icon: L.divIcon({
                className: 'leaflet-loc-marker',
                html: '<svg style="--loc-marker-color: #D32F2F;" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m32 0a24.0319 24.0319 0 0 0 -24 24c0 17.23 22.36 38.81 23.31 39.72a.99.99 0 0 0 1.38 0c.95-.91 23.31-22.49 23.31-39.72a24.0319 24.0319 0 0 0 -24-24zm0 35a11 11 0 1 1 11-11 11.0066 11.0066 0 0 1 -11 11z"/></g></svg>',
                iconSize: [24, 24],
                iconAnchor: [12, 24],
            })
        }
    }


    static generateRandomId = () => {
        return Math.random().toString(36).substring(2, 9);
    }


    static applyCustomColor = (pathOptions, customColor) => {
        let newOptions = {...pathOptions};
        newOptions.color = customColor;
        newOptions.fillColor = customColor;
        if (newOptions.templineStyle) {
            newOptions.templineStyle.color = customColor;
            newOptions.templineStyle.fillColor = customColor;
        }
        if (newOptions.hintlineStyle) {
            newOptions.hintlineStyle.color = customColor;
            newOptions.hintlineStyle.fillColor = customColor;
        }
        if (newOptions.pathOptions) {
            newOptions.pathOptions.color = customColor;
            newOptions.pathOptions.fillColor = customColor;
        }

        return newOptions;
    }


    static generateRandomColor = () => {
        let color = '#388E3C';
        let hue = Math.floor(Math.random() * 360);
        let rgb = ShapeStyling.hexToRgb(color);
        let hsl = ShapeStyling.rgbToHsl(rgb.r, rgb.g, rgb.b);
        hsl.h = hue;
        let newRgb = ShapeStyling.hslToRgb(hsl.h, hsl.s, hsl.l);
        return ShapeStyling.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
    }
    static hexToRgb = (hex) => {
        let r = parseInt(hex.substring(1, 3), 16);
        let g = parseInt(hex.substring(3, 5), 16);
        let b = parseInt(hex.substring(5, 7), 16);
        return { r, g, b };
    }
    static rgbToHex = (r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    static rgbToHsl = (r, g, b) => {
        r /= 255; g /= 255; b /= 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
        if (max === min) {
            h = s = 0;
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
        h = Math.round(h * 360);
        s = Math.round(s * 100);
        l = Math.round(l * 100);
        return { h, s, l };
    }
    static hslToRgb = (h, s, l) => {
        h /= 360;
        s /= 100;
        l /= 100;
        let r, g, b;
        if (s === 0) {
            r = g = b = l;
        } else {
            let hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }
            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }
        return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
    }

}