import React, {useEffect} from 'react';
import SimpleModal from "../ui/SimpleModal";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectShareAutocomplete from "./ProjectShareAutocomplete";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function ProjectShareModal({visible = false, onClick, onCancel, shares, onRemoveAccess}) {
    const notifications = useNotifications();

    const [shareLoading, setShareLoading] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [selectedLevel, setSelectedLevel] = React.useState('edit');


    const onSelectUser = (userId) => {
        setSelectedUser(userId);
    }


    const onLevelChange = (e) => {
        setSelectedLevel(e.target.value);
    }


    const onShareClick = (e) => {
        e.preventDefault();

        if (selectedUser === '') {
            notifications.notify('Please select user to share with', 'error');
            return;
        }

        onClick(selectedUser, selectedLevel);
    }


    const onRemoveAccessClick = (shareId) => {
        setShareLoading(shareId);
        onRemoveAccess(shareId);
    }


    return (
        <SimpleModal visible={visible} onClose={onCancel}>
            {shares && shares.length ? (
                <div className="mb-4">
                    <h4 className="text-uppercase fs-2 fw-semibold mb-2 pb-1">Users With Access</h4>
                    {shares.map((share, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <div>{share.invited.name}</div>
                                <div className="text-muted text-capitalize fs-2">{share.level}</div>
                            </div>

                            <ButtonWithLoading
                                className="btn-circle btn-light btn-sm justify-content-center"
                                iconClassName="ti ti-x fs-3"
                                noOriginalIconClassName={true}
                                loading={shareLoading === share.id}
                                spinnerClassName="spinner-border spinner-border-sm"
                                noOriginalSpinnerClassName={true}
                                onClick={() => {
                                    onRemoveAccessClick(share.id)
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : ('')}


            <h4 className="mb-2 pb-1 text-uppercase fs-2 fw-semibold">Add new Access</h4>

            <div className="mb-3">
                <p className="mb-1">Select user to share with:</p>

                <ProjectShareAutocomplete user={''} onChange={onSelectUser}/>
            </div>

            <div className="d-flex align-items-end justify-content-between gap-3">
                <select className="form-select w-50" onChange={onLevelChange}>
                    <option value="edit">Edit</option>
                    <option value="manage">Manage</option>
                </select>

                <button className="btn btn-primary" onClick={onShareClick}>Share</button>
            </div>
        </SimpleModal>
    );
}
