import React, { useState, useEffect, useRef } from 'react';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";

export default function ProjectShareAutocomplete({user, onChange}) {
    const [currentUser, setCurrentUser] = useState(user);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);


    useEffect(() => {
        setCurrentUser(user);
    }, [user]);


    const onSearch = (query) => {
        setIsLoading(true);

        usersModel.autocomplete(query, null).then((response) => {
            if (response.length) {
                let data = response.map((user) => {
                    return {
                        value: user.id,
                        name: user.email
                    }
                });
                setOptions(data);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };


    const processOnChange = (selected) => {
        if (selected.length > 0) {
            setCurrentUser(selected[0]);

            onChange(selected[0].value);
        }
    }


    return (
        <div className="position-relative">
            <div className="position-absolute end-0 top-50 translate-middle-y pe-3 z-index-5"><i className="ti ti-search fs-4"></i></div>

            <AsyncTypeahead
                id={'top-search'}
                isLoading={isLoading}
                labelKey={option => `${option.name}`}
                onSearch={onSearch}
                onChange={processOnChange}
                options={options}
                className={'form-control input-onmap'}
                placeholder={currentUser.name ? currentUser.name : 'Search...'}
            />
        </div>
    );
}