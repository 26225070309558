import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";
import ProjectSharesSearchForm from "./ProjectSharesSearchForm";
import {Link} from "react-router-dom";
import ProjectSharesModel from "../../models/ProjectSharesModel";

export default function ProjectSharesList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const projectSharesModel = new ProjectSharesModel(apiContext.api);
    const projectsModel = new ProjectsModel(apiContext.api);


    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'date', direction: 'desc'}
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        projectSharesModel.list(filter.searchText, filter.sorting, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Shared Projects | Geolist';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/p/shared';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onDeleteClick = (projectId) => {
        setLoading(true);

        projectsModel.delete(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project deleted', 'success');
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onCopyClick = (projectId) => {
        setLoading(true);

        projectsModel.copy(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project copied', 'success');
                window.location.href = '/p';
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <SimpleCardContainer
                title={'Shared Projects'}
                loading={loading}
            >
                <div className={'pt-2 pb-4'}>
                    <ProjectSharesSearchForm onSearch={onSearch}/>
                </div>

                {(data && data.length || loading) ? (
                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'title') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="title" onClick={onSort}>
                                    Project Title
                                    {(filter.sorting.field === 'title') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'title') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th style={{width: '200px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'last_updated') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="last_updated" onClick={onSort}>
                                    Last Updated
                                    {(filter.sorting.field === 'last_updated') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'last_updated') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex gap-1 flex-column">
                                            <Link to={'/p/'+item.id} className="d-block">{item.title}</Link>
                                            {item.description ? (
                                            <p>{item.description.split('\n').map((str, index, array) =>
                                                ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
                                            )}</p>
                                            ) : ''}
                                        </div>
                                    </td>
                                    <td>{(new Date(item.updated_at)).toLocaleString()}</td>
                                    <td>
                                        <div className="d-flex gap-3">
                                            <button className="btn btn-circle btn-light text-dark" onClick={() => {
                                                onCopyClick(item.id)
                                            }}>
                                                <i className="ti ti-copy fs-4"></i>
                                            </button>
                                            {item.shares && item.shares[0].level === 'manage' && (
                                            <button className="btn btn-circle btn-light-primary text-primary">
                                                <i className="ti ti-share fs-4"></i>
                                            </button>
                                            )}
                                            <button className="btn btn-circle btn-light-danger text-danger" onClick={() => {
                                                onDeleteClick(item.id)
                                            }}>
                                                <i className="ti ti-trash fs-4"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                ) : (
                <div className="text-center fs-2 mt-4">
                    No shared projects found.
                </div>
                )}
            </SimpleCardContainer>
        </>
    );
}