import React, { useEffect } from 'react';

import * as L from "leaflet";

import MapHelper from "./../../../../helpers/MapHelper";


export default function MapTypeControl({ onChange }) {
    const [ddOpen, setDdOpen] = React.useState(false);
    const layers = {
        osm: 'Open Street Maps',
        mapbox: 'Mapbox',
        google: 'Google Maps'
    };


    const onTypeChange = (e, layerName) => {
        e.preventDefault();

        setDdOpen(false);
        onChange(layerName);
    }


    return (
        <div className="btn-group-vertical">
            <button type="button" className="btn btn-onmap" aria-haspopup="true" aria-expanded="false" onClick={() => { setDdOpen(!ddOpen) }}>
                <i className={'ti ti-box-multiple fs-5'}></i>
            </button>

            <div className={'dropdown-menu' + (ddOpen ? ' show' : '')}>
                {Object.keys(layers).map((layerName, lIndex) => {
                    return (
                        <a href="#" className="dropdown-item" onClick={(e) => {onTypeChange(e, layerName)}} key={lIndex}>{layers[layerName]}</a>
                    );
                })}
            </div>
        </div>
    );
}


