import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsModel from "../../models/ProjectsModel";
import Map from "../map/general/Map";
import {useAuth} from "../../providers/AuthProvider";

export default function ProjectCreate(props) {
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();
    const {user} = useAuth();
    if (user && user.id) {
        projectsModel.create().then((response) => {
            if (response && response.id) {
                notifications.notify('Project created!', 'success');
                setTimeout(() => {
                    window.location.href = '/p/'+response.id;
                }, 1000);
            } else {
                uploadErrorHandler(response);
            }
        }).catch((error) => {
            uploadErrorHandler(error.response);
        });
    }

    const [data, setData] = React.useState({});


    useEffect(() => {
    }, []);


    const uploadErrorHandler = (response) => {
        if (response && response.error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    return (
        <Map project={data} hideToolbar={false} />
    );
}
