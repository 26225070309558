import {useEffect, useRef, useState} from "react";
import CoordinatesModel from "../../../models/CoordinatesModel";

export default function MarkerPopup({marker, apiContext}) {
    const coordinatesModel = new CoordinatesModel(apiContext.api);

    const [info, setInfo] = useState(null);

    useEffect(() => {
        if (!marker) {return;}

        coordinatesModel.coordinatesInfo(marker.getLatLng().lat, marker.getLatLng().lng).then((data) => {
            let newInfo = 'CatId: '+data.catid+' | '+'Type: '+data.type;
            setInfo(newInfo);
        });
    }, [marker]);

    return (
        <div>{info}</div>
    );
}